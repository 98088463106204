import { useStaticQuery, graphql, } from "gatsby"
import React from 'react'
import Img from "gatsby-image"
import Lettering from '../images/lettering-image@2x.gif'
import Doodling from '../images/doodle-image@2x.gif'
import Arrow from '../images/arrow.svg'


const About = () => {
    var letteringOverlayLocation = {Lettering};
    var doodlingOverlayLocation  = {Doodling};

    const data = useStaticQuery(graphql`
        query {
            mainImage: file(relativePath: { eq: "AboutMe@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1196, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)  
    
    function displayLetteringOverlay() {
        var letteringOverlay = document.getElementById("lettering-image");
        letteringOverlay.src = letteringOverlayLocation.Lettering;
    }
    function hideLetteringOverlay() {
        var letteringOverlay = document.getElementById("lettering-image");
        letteringOverlay.src = "data:image/gif;base64,R0lGODlhAQABAHAAACH5BAUAAAAALAAAAAABAAEAAAICRAEAOw==";    
    }
    function displayDoodlingOverlay() {
        var doodlingOverlay = document.getElementById("lettering-image");
        doodlingOverlay.src = doodlingOverlayLocation.Doodling;
    }
    function hideDoodlingOverlay() {
        var doodlingOverlay = document.getElementById("lettering-image");
        doodlingOverlay.src = "data:image/gif;base64,R0lGODlhAQABAHAAACH5BAUAAAAALAAAAAABAAEAAAICRAEAOw==";    
    }
    return (
        <div>   
            <div className = "navbar-spacing"></div>
            <div className = "background background-dark-gray">
                <div className = "home-container about-container">
                    <div className = "home-left relative-position about-left">
                        <Img className = "about-image" fluid={data.mainImage.childImageSharp.fluid} alt="Myself" />
                        <img id="lettering-image" className = "about-overlay" src="data:image/gif;base64,R0lGODlhAQABAHAAACH5BAUAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Lettering"/>
                        <img id="doodling-image" className = "about-overlay" src="data:image/gif;base64,R0lGODlhAQABAHAAACH5BAUAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Doodling" />
                    </div>
                    <div className = "home-right about-right">
                        <div className = "summary-header white-text">Eric Lam</div>
                        <div className = "summary-text intro-summary white-text"><b>User Experience Designer</b></div>
                        <div className = "summary-text white-text">
                            <b>Experienced in —</b>
                            <br/>Designing and shipping enterprise products
                            <br/>Improving and expanding design systems
                            <br/>Facilitating client and user workshops
                            <br/>Synthesizing quantitative + qualitative data

                            <br/>
                            <br/><b>Passionate about —</b>
                            <br/>Solving complex problems
                            <br/>Crafting delightful experiences
                            <br/>Bridging the gap between design + development
                            <br/>Connecting and collaborating with humans
                            <br/>Measuring impact
                            <br/>
                            <br/><b>Also enjoy —</b>
                            <br/><span className = "" role="presentation" onMouseOver={displayLetteringOverlay} onMouseOut={hideLetteringOverlay} onFocus={displayLetteringOverlay} onBlur={hideLetteringOverlay}>Hand Lettering</span>
                            <br/><span className = "" role="presentation" onMouseOver={displayDoodlingOverlay} onMouseOut={hideDoodlingOverlay} onFocus={displayDoodlingOverlay} onBlur={hideDoodlingOverlay}>Doodling</span>
                            <br/><a href="mailto:ericlam1196@gmail.com" target="_top" className="intro-link">Chatting (ericlam1196@gmail.com)</a>
                        </div>
                            <a className = "button-project" href="https://drive.google.com/file/d/1bsP6oVkuJnbFppP6IZX8dAoWaA7sNuCy/view?usp=sharing" target="_blank" rel="noreferrer" >
                                View Resume
                                <img className = "button-arrow" src={Arrow} alt="Arrow"/>
                            </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;