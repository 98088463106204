import React from "react"
import "../styles/styles.scss"
import { Helmet } from "react-helmet"


//Import Components
import Header from "../components/header"
import About from "../components/about"
import Footer from "../components/footer"

const IndexPage = () => (
  <div>
    <Helmet>
      <title>Eric Lam — User Experience Designer</title>
    </Helmet>
    <Header/>
    <About />
    <Footer />
  </div>
)

export default IndexPage
